// import smoothScroll from './components/smoothScroll';
// import backTop from './components/backTop';
// import toggleNavigation from './components/toggleNavigation';
// import toggleSublist from './components/toggleSublist';
// import toggleBox from './components/toggleBox';
// import loadPref from './components/loadPref';
// import formFunc from './components/formFunc';
// import searchPostal from './components/searchPostal';
// import loginFunc from './components/loginFunc';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    // smoothScroll();
    // backTop();
    // toggleNavigation();
    // toggleSublist();
    // toggleBox();
    // loadPref();
    // loginFunc();

    // const onlineTourForm = document.querySelector('.online-tour .form') || null;
    // const hasPostal = document.querySelector('.has-postal-search') || null;
    // // const isLogin = document.querySelector('.is-login');
    // const isContact = document.querySelector('.is-contact');
    // let otaVars = [
    //   {
    //     elName: 'date',
    //     otherElSelector: 'other-date',
    //     otherValue: '上記以外の日程希望'
    //   },
    //   {
    //     elName: 'occupation',
    //     otherElSelector: 'other-occ',
    //     otherValue: 'その他'
    //   }
    // ];
    // // let loginVars = [
    // //   otaVars[1],
    // //   {
    // //     elName: 'reason',
    // //     otherElSelector: 'other-reason',
    // //     otherValue: 'その他'
    // //   }
    // // ];
    // let contactVars = [
    //   otaVars[1],
    //   {
    //     elName: 'inquiry',
    //     otherElSelector: 'other-inq',
    //     otherValue: 'その他'
    //   }
    // ];

    // if (onlineTourForm) {
    //   formFunc(otaVars);
    // }

    // // if (isLogin) {
    // //   formFunc(loginVars);
    // // }

    // if (isContact) {
    //   formFunc(contactVars);
    // }

    // if (hasPostal) {
    //   let head = document.getElementsByTagName('head')[0];
    //   let script = document.createElement('script');

    //   script.type = 'text/javascript';
    //   script.charset = 'UTF-8';
    //   script.src = 'https://ajaxzip3.github.io/ajaxzip3.js';
    //   head.appendChild(script);
    //   searchPostal();
    // }
  },
  false
)
